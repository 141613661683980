  .index {
    text-align: center;
  }
  .title > h1{
    font-size: 60px;
    color: white;
    background-color: darkcyan;
    padding: 50px;
  }
  .background{
    padding-left: 350px;
    padding-right: 350px;
    padding-bottom: 30px;
  }
  .instruction{
    padding-left: 500px;
    padding-right: 500px;
  }
  .audio #a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .audio {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img{
    width: 300px;
    height: 200px;
    border: solid;
    border-radius: 200px;
  }
  .answerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }
  input[type="text"] {
    width: 500px;
    margin-bottom: 50px;
}